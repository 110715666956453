import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { ApolloQueryResult, FetchResult, MutationOptions, QueryOptions, SubscriptionOptions } from '@apollo/client/core';
import { ApolloError } from '@apollo/client/errors';
import { deepDistinctUntilChanged } from '@rcg/standalone/rxjs-operators/deep-distinct-until-changed';
import { DateUtils } from '@rcg/standalone/utils/date-utils';
import { Apollo, MutationResult, WatchQueryOptions } from 'apollo-angular';
import {
  DefinitionNode,
  DirectiveNode,
  DocumentNode,
  Kind,
  NamedTypeNode,
  OperationDefinitionNode,
  SelectionNode,
  SelectionSetNode,
  StringValueNode,
  VariableDefinitionNode,
} from 'graphql';
import { GraphQLError } from 'graphql/error';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  interval,
  map,
  MonoTypeOperatorFunction,
  Observable,
  of,
  ReplaySubject,
  retry,
  Subscription,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';

export interface GraphqlClientServiceOptions {
  isDebugMode?: () => boolean;
  apollo$?: Observable<Apollo | undefined>;
  forceProvidedApollo?: boolean;
  locale$?: Observable<string>;
}

export const GRAPHQL_CLIENT_SERVICE_OPTIONS = new InjectionToken<GraphqlClientServiceOptions>('GraphQLClientServiceOptions');

@Injectable({
  providedIn: 'root',
})
export class GraphqlClientService implements OnDestroy {
  private apollo$ = new ReplaySubject<Apollo>(1);

  private static connectionErrorSubject = new BehaviorSubject<{ error: Error | ApolloError; firstErrorAt: Date } | null>(null);

  public static connectionErrorString$ = GraphqlClientService.connectionErrorSubject.pipe(
    switchMap((e) => {
      if (!e) return of(null);

      const errors: string[] = [];

      const errorMsg = (err: Error | ApolloError) => `${err.name ? `${err.name}: ` : ''}${err.message}`;

      if (e.error instanceof ApolloError) {
        for (const err of e.error.clientErrors) {
          errors.push(errorMsg(err));
        }

        for (const err of e.error.graphQLErrors) {
          errors.push(`${errorMsg(err)} (${JSON.stringify(err.extensions)})`);
        }
      } else {
        errors.push(errorMsg(e.error));
      }

      const joinedErrors = errors.join('\n');

      return interval(200).pipe(
        map(() => DateUtils.dateToNow(e.firstErrorAt.toISOString())),
        map((since) => `Trajanje: ${since}\n\n${joinedErrors}`),
      );
    }),
    distinctUntilChanged(),
  );

  private static options?: GraphqlClientServiceOptions | null;

  private apolloSub?: Subscription;

  constructor(
    @Inject(GRAPHQL_CLIENT_SERVICE_OPTIONS) @Optional() options: GraphqlClientServiceOptions | null,
    private initialApollo: Apollo,
  ) {
    GraphqlClientService.options = options;

    if (options?.forceProvidedApollo) {
      this.apollo$.next(initialApollo);
      return;
    }

    this.apolloSub = options?.apollo$?.subscribe((apollo) => this.apollo$.next(apollo ?? initialApollo));
  }

  ngOnDestroy(): void {
    this.apolloSub?.unsubscribe();
    this.apollo$.unsubscribe();
  }

  static mapResult<T>() {
    return map((result: ApolloQueryResult<T> | MutationResult<T> | FetchResult<T, Record<string, unknown>, Record<string, unknown>>) => {
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty('error') && (result as ApolloQueryResult<T>).error) {
        throw new Error(GraphqlClientService.parseApolloError((result as ApolloQueryResult<T>).error!));
      }
      if (result.errors && result.errors.length > 0) {
        throw new Error(GraphqlClientService.parseGraphQlErrors(result.errors));
      }
      if (!result.data) {
        throw new Error('No server data result');
      }
      return result.data;
    });
  }

  private static handleConnectionErrors<T>(): MonoTypeOperatorFunction<T> {
    return (source) => {
      return source.pipe(
        retry<T>({
          delay: (error, count) => {
            const handleConnectionError = (error: Error | ApolloError) => {
              if (count > 3) {
                this.connectionErrorSubject.next({
                  error,
                  firstErrorAt: this.connectionErrorSubject.value?.firstErrorAt ?? new Date(),
                });
              }

              return timer(Math.min(3, count) * 500);
            };

            if (error instanceof Error) {
              if (error instanceof ApolloError) {
                if (error.message.includes('connection error')) return handleConnectionError(error);
                if (/Http failure response for https:\/\/.*\/v1\/graphql/.test(error.message)) return handleConnectionError(error);

                return throwError(() => error);
              }

              if (error.message.includes('Socket closed')) return handleConnectionError(error);

              return throwError(() => error);
            }

            return throwError(() => error);
          },
          resetOnSuccess: true,
        }),
        tap(() => {
          this.connectionErrorSubject.next(null);
        }),
      );
    };
  }

  private static debug(name: string, definitions: readonly DefinitionNode[], ...debugArgs: unknown[]) {
    if (!this.options?.isDebugMode || !this.options.isDebugMode()) return;

    try {
      const operationDefs = definitions.filter((d) => d.kind === 'OperationDefinition') as OperationDefinitionNode[];

      for (const def of operationDefs) {
        const selections = def.selectionSet.selections.map((s) => (s.kind === Kind.FIELD ? s.name.value : s));

        console.log('[GraphQL]', name, def.name?.value, ...(selections.length ? [`(${selections.join(', ')})`] : []), ...debugArgs);
      }
    } catch (error) {
      console.error('GraphQL debug error:', error);
    }
  }

  private static mapResponseForDebug(res: Record<string, unknown>) {
    let o: unknown = res;
    const path = ['$'];

    for (;;) {
      if (typeof o !== 'object' || !o) break;
      const keys = Object.keys(o).filter((k) => k !== '__typename');
      if (keys.length !== 1) break;
      path.push(keys[0]);
      o = (o as Record<string, unknown>)[keys[0]];
    }

    return [path.join('.'), o];
  }

  private static remapRcgLocaleVariableDefinitions<
    N extends DocumentNode | DefinitionNode | VariableDefinitionNode | SelectionSetNode | SelectionNode | DirectiveNode,
  >(node: N, options: { locale: string }): N {
    const mappedNode = {
      ...node,
      definitions:
        'definitions' in node && node.definitions
          ? node.definitions.map((def) => this.remapRcgLocaleVariableDefinitions(def, options))
          : undefined,
      variableDefinitions:
        'variableDefinitions' in node && node.variableDefinitions
          ? node.variableDefinitions.map((def) => this.remapRcgLocaleVariableDefinitions(def, options))
          : undefined,
      selectionSet:
        'selectionSet' in node && node.selectionSet ? this.remapRcgLocaleVariableDefinitions(node.selectionSet, options) : undefined,
      selections:
        'selections' in node && node.selections
          ? node.selections.map((sel) => this.remapRcgLocaleVariableDefinitions(sel, options))
          : undefined,
    };

    if (node.kind !== Kind.VARIABLE_DEFINITION) return mappedNode;
    if (node.type.kind !== Kind.NAMED_TYPE) return mappedNode;
    if (node.type.name.value !== 'RcgUserLocale') return mappedNode;

    const type: NamedTypeNode = {
      kind: Kind.NAMED_TYPE,
      name: {
        kind: Kind.NAME,
        value: 'String',
      },
    };

    const defaultValue: StringValueNode = {
      kind: Kind.STRING,
      value: options.locale,
      block: false,
    };

    return {
      ...mappedNode,
      type,
      defaultValue,
    };
  }

  private static mapQueryOptions$<O extends { query: DocumentNode }>(options: O): Observable<O> {
    return (GraphqlClientService.options?.locale$ ?? of('sl_SI')).pipe(
      map((locale) => ({
        ...options,
        query: GraphqlClientService.remapRcgLocaleVariableDefinitions(options.query, {
          locale,
        }),
      })),
    );
  }

  static queryWithApollo<T>(apollo: Observable<Apollo>, options: QueryOptions): Observable<T> {
    const options$ = GraphqlClientService.mapQueryOptions$(options).pipe(map(GraphqlClientService.setDefaultFetchPolicyIfNotSet));

    return combineLatest([apollo, options$]).pipe(
      tap(([, opt]) => GraphqlClientService.debug('query', opt.query.definitions, opt.variables)),
      switchMap(([apollo, opt]) => apollo.query(opt)),
      GraphqlClientService.handleConnectionErrors(),
      GraphqlClientService.mapResult<T>(),
      deepDistinctUntilChanged(),
      tap((res) =>
        GraphqlClientService.debug('query response', options.query.definitions, ...GraphqlClientService.mapResponseForDebug(res)),
      ),
    );
  }

  query<T>(options: QueryOptions): Observable<T> {
    return GraphqlClientService.queryWithApollo(this.apollo$, options);
  }

  anonymousQuery<T>(options: QueryOptions): Observable<T> {
    return GraphqlClientService.queryWithApollo(of(this.initialApollo), options);
  }

  watchQuery<T>(options: WatchQueryOptions): Observable<T> {
    const options$ = GraphqlClientService.mapQueryOptions$(options).pipe(map(GraphqlClientService.setDefaultFetchPolicyIfNotSet));

    return combineLatest([this.apollo$, options$]).pipe(
      tap(([, opt]) => GraphqlClientService.debug('watchQuery', opt.query.definitions, opt.variables)),
      switchMap(([apollo, opt]) => apollo.watchQuery(opt).valueChanges),
      GraphqlClientService.handleConnectionErrors(),
      GraphqlClientService.mapResult<T>(),
      deepDistinctUntilChanged(),
      tap((res) =>
        GraphqlClientService.debug('watchQuery response', options.query.definitions, ...GraphqlClientService.mapResponseForDebug(res)),
      ),
    );
  }

  mutate<T>(options: MutationOptions): Observable<T> {
    const opt = GraphqlClientService.setDefaultFetchPolicyIfNotSet(options);

    GraphqlClientService.debug('mutate', opt.mutation.definitions, opt.variables);

    return this.apollo$.pipe(
      switchMap((apollo) => apollo.mutate(opt)),
      GraphqlClientService.mapResult<T>(),
      deepDistinctUntilChanged(),
      tap((res) =>
        GraphqlClientService.debug('mutation response', opt.mutation.definitions, ...GraphqlClientService.mapResponseForDebug(res)),
      ),
    );
  }

  subscribe<T>(options: SubscriptionOptions): Observable<T> {
    const options$ = GraphqlClientService.mapQueryOptions$(options).pipe(map(GraphqlClientService.setDefaultFetchPolicyIfNotSet));

    return combineLatest([this.apollo$, options$]).pipe(
      tap(([, opt]) => GraphqlClientService.debug('subscribe', opt.query.definitions, opt.variables)),
      switchMap(([apollo, opt]) => apollo.subscribe(opt)),
      GraphqlClientService.handleConnectionErrors(),
      GraphqlClientService.mapResult<T>(),
      deepDistinctUntilChanged(),
      tap((res) =>
        GraphqlClientService.debug('subscription response', options.query.definitions, ...GraphqlClientService.mapResponseForDebug(res)),
      ),
    );
  }

  private static parseApolloError(error: ApolloError) {
    const errorMessage = error.graphQLErrors[0].message ?? error!.toString();
    return errorMessage;
  }

  private static parseGraphQlErrors(errors?: ReadonlyArray<GraphQLError>) {
    if (!errors || !errors.length) return JSON.stringify(errors);
    return errors
      .map((e) =>
        'toJSON' in e && typeof e.toJSON === 'function'
          ? JSON.stringify(e.toJSON())
          : e?.message
          ? `${e.message}  ${e.extensions?.code ? `Code: ${e.extensions.code}` : ''} ${
              e.extensions?.path ? `Path: ${e.extensions?.path}` : ''
            }`
          : e.toString(),
      )
      .join('\n');
  }

  private static setDefaultFetchPolicyIfNotSet<O extends QueryOptions | MutationOptions | SubscriptionOptions | WatchQueryOptions>(
    options: O,
  ): O {
    return {
      ...options,
      fetchPolicy: options.fetchPolicy ?? 'no-cache',
    };
  }
}
