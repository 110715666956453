import { Injector, NgModule } from '@angular/core';
import { APP_AUTH_TOKEN_GETTER } from '@rcg/standalone/injection-tokens/auth-token';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { CreateApolloClientFactory } from './apollo-client';
import { GRAPHQL_APOLLO_CLIENT_OPTIONS } from './options-injection-token';

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, injector: Injector) => {
        const options = injector.get(GRAPHQL_APOLLO_CLIENT_OPTIONS);
        const getToken = injector.get(APP_AUTH_TOKEN_GETTER);
        return CreateApolloClientFactory(httpLink, options, getToken);
      },
      deps: [HttpLink, Injector],
    },
  ],
})
export class GraphQLModule {}
