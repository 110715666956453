import { format, parseISO } from 'date-fns';
import { sl } from 'date-fns/locale';

const timeDeltaUnits = [
  [31536000, 'y'],
  [2592000, 'M'],
  [604800, 'w'],
  [86400, 'd'],
  [3600, 'h'],
  [60, 'm'],
  [1, 's'],
] as const;

type TimeDeltaUnits = (typeof timeDeltaUnits)[number][1];

const timeDeltaLangMap: {
  [locale: string]: {
    [K in TimeDeltaUnits]: [string, string, string, string, string];
  };
} = {
  en_US: {
    y: ['y', 'year', 'years', 'years', 'years'],
    M: ['M', 'month', 'months', 'months', 'months'],
    w: ['w', 'week', 'weeks', 'weeks', 'weeks'],
    d: ['d', 'day', 'days', 'days', 'days'],
    h: ['h', 'hour', 'hours', 'hours', 'hours'],
    m: ['m', 'minute', 'minutes', 'minutes', 'minutes'],
    s: ['s', 'second', 'seconds', 'seconds', 'seconds'],
  },
  sl_SI: {
    y: ['l', 'leto', 'leti', 'leta', 'let'],
    M: ['M', 'mesec', 'meseca', 'meseci', 'mesecev'],
    w: ['t', 'teden', 'tedna', 'tedni', 'tednov'],
    d: ['d', 'dan', 'dni', 'dnevi', 'dni'],
    h: ['h', 'ura', 'uri', 'ure', 'ur'],
    m: ['m', 'minuta', 'minuti', 'minute', 'minut'],
    s: ['s', 'sekunda', 'sekundi', 'sekunde', 'sekund'],
  },
  bs_BA: {
    y: ['g', 'godina', 'godine', 'godine', 'godina'],
    M: ['M', 'mjesec', 'mjeseca', 'mjeseci', 'mjeseci'],
    w: ['S', 'sedmica', 'sedmice', 'sedmica', 'sedmica'],
    d: ['d', 'dan', 'dana', 'dana', 'dana'],
    h: ['h', 'sat', 'sata', 'sati', 'sati'],
    m: ['m', 'minuta', 'minute', 'minuta', 'minuta'],
    s: ['s', 'sekunda', 'sekunde', 'sekundi', 'sekundi'],
  },
};

export class DateUtils {
  static isToday(date: Date | null | undefined): boolean {
    if (!date) {
      return false;
    }
    const today = new Date();
    try {
      const isToday =
        date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
      return isToday;
    } catch (error) {
      return false;
    }
  }

  static stringToDate(dateStr: string, formatStr: string) {
    if (!dateStr) {
      return '';
    }
    try {
      return format(parseISO(dateStr), formatStr, { locale: sl }).toString();
    } catch (error) {
      return dateStr?.toString() || '';
    }
  }

  static dateToUTC(date: Date): Date | null {
    if (!date) {
      return null;
    }
    return new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      ),
    );
  }

  static secondsToTime(seconds: number | undefined, showSeconds = false): string {
    if (!seconds) {
      return '';
    }
    try {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor(seconds / 60) % 60;

      const hh = hours < 10 ? `0${hours}` : hours;
      const mm = minutes < 10 ? `0${minutes}` : minutes;

      if (showSeconds) {
        const remainingSeconds = seconds % 60;
        const ss = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
        return `${hh}:${mm}:${ss}`;
      } else {
        return `${hh}:${mm}`;
      }
    } catch (err) {
      return seconds?.toString() || '';
    }
  }

  static dateToNow(value: string, lang = 'sl_SI'): string {
    if (!value) {
      return value;
    }
    try {
      const before = DateUtils.dateToUTC(new Date(value))!.getTime();

      const now = DateUtils.dateToUTC(new Date())!.getTime();

      // time since before date and now in seconds as delta
      const delta = (now - before) / 1000;
      return this.timeDelta(delta, lang);
    } catch (err) {
      return value || '';
    }
  }

  private static getApproximateTime(seconds: number): number {
    for (let i = 0; i < timeDeltaUnits.length; i++) {
      const n = timeDeltaUnits[i][0];

      if (seconds >= n) {
        return Math.floor(seconds / n) * n;
      }
    }

    return Math.floor(seconds);
  }

  static timeDelta(delta: number, locale = 'sl_SI', exact = false, short = false) {
    const lang = Object.keys(timeDeltaLangMap).includes(locale) ? locale : 'sl_SI';
    let num = exact ? delta : this.getApproximateTime(delta);

    const out = timeDeltaUnits.map(() => 0);

    while (num > 0) {
      if (num < 1) {
        out[out.length - 1] += num;
        break;
      }

      for (let i = 0; i < timeDeltaUnits.length; i++) {
        const [size] = timeDeltaUnits[i];

        if (num >= size) {
          num -= size;
          out[i]++;
          break;
        }
      }
    }

    const result = out
      .map((n, i) => {
        if (!n) return undefined;

        const unit = timeDeltaUnits[i][1];
        const pluralIndex = short ? 0 : n === 1 ? 1 : n === 2 ? 2 : n === 3 || n === 4 ? 3 : 4;

        return n + (short ? '' : ' ') + timeDeltaLangMap[lang][unit][pluralIndex];
      })
      .filter((u) => u)
      .join(' ');

    if (result) return result;

    const pluralIndex = delta === 1 ? 0 : delta === 2 ? 1 : delta === 3 || delta === 4 ? 2 : 3;
    return delta + ' ' + (short ? 's' : timeDeltaLangMap[lang].s[pluralIndex]);
  }

  static startDayDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  }

  static endDayDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 0);
  }

  static removeTimezone(date: string) {
    if (date.endsWith('Z')) return date.slice(0, -1);
    if (date.includes('+')) return date.split('+')[0];

    return date;
  }

  static formatDate(date?: Date): string | null {
    if (!date) return null;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const monthString = month < 10 ? '0' + month : '' + month;
    const dayString = day < 10 ? '0' + day : '' + day;

    return `${year}-${monthString}-${dayString}`;
  }
}
