import { ApolloClientOptions, ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { AppAuthTokenGetter } from '@rcg/standalone/injection-tokens/auth-token';
import { HttpLink, Options } from 'apollo-angular/http';
import { Kind, OperationTypeNode } from 'graphql';
import { ClientOptions, createClient } from 'graphql-ws';

interface LikeCloseEvent {
  readonly code: number;
  readonly reason: string;
}

export type ApolloClientHeaders = Promise<Record<string, string | undefined>> | Record<string, string | undefined> | undefined;

export interface ApolloClientFactoryOptions {
  isProduction: boolean;
  getUri: () => Options['uri'];
  getWSUri: () => ClientOptions['url'];
  getHeaders?: () => ApolloClientHeaders;
}

export function CreateApolloClientFactory(
  httpLink: HttpLink,
  options: ApolloClientFactoryOptions,
  tokenGetter: AppAuthTokenGetter,
): ApolloClientOptions<unknown> {
  const getUri = options.getUri;
  const getWSUri = options.getWSUri;
  const getToken = tokenGetter ?? (() => undefined);
  const getHeaders = options.getHeaders ?? (() => ({} as ApolloClientHeaders));

  const getAuthHeaders = async (headers = {}) => {
    const token = await getToken();
    const additionalHeaders = await getHeaders();

    if (!token) {
      return {
        ...headers,
        ...additionalHeaders,
      };
    } else {
      return {
        headers: {
          ...headers,
          ...additionalHeaders,
          Authorization: `Bearer ${token}`,
        },
      };
    }
  };

  const auth = setContext(async (_, { headers }) => {
    return await getAuthHeaders(headers);
  });

  const http = httpLink.create({
    uri: getUri(),
  });

  const authHttp = ApolloLink.from([auth, http]);

  const isLikeCloseEvent = (val: unknown): val is LikeCloseEvent => {
    return !!val && typeof val == 'object' && 'code' in val && 'reason' in val;
  };

  const onNonLazyError = (name: string) => (e: unknown) => {
    if (isLikeCloseEvent(e)) {
      console.error('WebSocket', name, 'link close error:', e.code, e.reason);
    } else {
      console.error('WebSocket', name, 'link error:', e);
    }
  };

  const ws = new GraphQLWsLink(
    createClient({
      url: getWSUri(),
      connectionParams: getAuthHeaders,
      lazy: false,
      onNonLazyError: onNonLazyError('subscription'),
    }),
  );

  const link = split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return def.kind === Kind.OPERATION_DEFINITION && def.operation === OperationTypeNode.SUBSCRIPTION;
    },
    ws,
    authHttp,
  );

  return {
    link: link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    connectToDevTools: !options.isProduction,
  };
}
